<template>
  <div class="rule">
    <!--  标签页-->
    <el-tabs v-model="activeName" class="el-nav" @tab-click="handleClick">
      <!--        搜索查询-->
      <div class="search space-between" style="margin: 15px 0 20px 0">
        <div class="flex-nowrap top-list">
          <el-select v-model="options" clearable placeholder="筛选" class="search-types">
            <el-option v-for="item in optionsData" :key="item.value" :label="item.label" :value="item.value"> </el-option>
          </el-select>

          <el-input v-model="keywords" placeholder="请输入查询条件" class="search-input"></el-input>
          <div class="" v-if="activeName !== 'six' && activeName !== 'sev'">
            <span style="margin-right: 10px">标签</span>
            <el-autocomplete class="inline-input" style="margin-right: 20px" v-model="tagvalue" :fetch-suggestions="querySearch" placeholder="请输入内容"></el-autocomplete>
          </div>
          <div class="" v-if="isEltab !== 2">
            <span style="margin-right: 10px">状态</span>
            <el-select v-model="status" placeholder="请选择" class="search-type" style="margin-right: 20px">
              <el-option v-for="item in statusData" :key="item.value" :label="item.label" :value="item.value"> </el-option>
            </el-select>
          </div>

          <!-- <div class="" v-if="isEltab !== 1">
            <span style="margin-right: 10px;">类型</span>
            <el-select v-model="type" placeholder="请选择" class="search-type" style="margin-right: 0">
              <el-option
                v-for="item in typeData"
                :key="item.value"
                :label="item.label"
                :value="item.value">
              </el-option>
            </el-select></div> -->

          <el-button type="primary" class="search-btn" @click="screenSubmit">查询</el-button>
          <el-button class="search-btn reset" @click="screenDefault">重置</el-button>
          <el-dropdown @command="go($event)" class="f-r search-add" trigger="click" v-if="activeName !== 'four' && activeName !== 'five' && activeName !== 'six' && activeName !== 'sev'">
            <el-button type="primary" class="search-add"> 新增创作<i class="el-icon-arrow-down el-icon--right"></i> </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="text"><div class="" style="width: 59px; text-align: center">写文章</div></el-dropdown-item>
              <el-dropdown-item command="video"><div class="" style="width: 59px; text-align: center">发视频</div></el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
        <!--                    <el-button type="warning" class="f-r search-add" @click="add()">新增创作</el-button>-->
      </div>
      <div class="total space-between">
        <div class="total-left">
          共有
          <span class="total-left-text">{{ creationData.meta ? (creationData.meta.total > 9999 ? (creationData.meta.total / 10000).toFixed(2) + 'W' : creationData.meta.total) : 0 }}</span>
          条数据
        </div>
      </div>
      <el-tab-pane :label="num.all > 9999 ? '全部 ' + (num.all / 10000).toFixed(2) + 'W' : '全部 ' + num.all" name="first"> </el-tab-pane>
      <el-tab-pane :label="num.article > 9999 ? '文章 ' + (num.article / 10000).toFixed(2) + 'W' : '文章 ' + num.article" name="two"> </el-tab-pane>
      <el-tab-pane :label="num.video > 9999 ? '视频 ' + (num.video / 10000).toFixed(2) + 'W' : '视频 ' + num.video" name="three"> </el-tab-pane>
      <el-tab-pane :label="num.draft > 9999 ? '草稿 ' + (num.draft / 10000).toFixed(2) + 'W' : '草稿 ' + num.draft" name="four">
        <div class="statistical-bottom-list" v-loading="loading">
          <div v-if="creationData.data && creationData.data.length">
            <div class="statistical-bottom-item" v-for="(item, index) in creationData.data" :key="index">
              <div class="statistical-bottom-item-top vertical-center">
                <el-tag v-if="item.content_type === 1">文章</el-tag>
                <el-tag v-else-if="item.content_type === 2">视频</el-tag>
                <el-tag v-else-if="item.content_type === 3">推广</el-tag>
                <div class="statistical-bottom-item-top-title white-space">{{ item.title }}</div>
                <div class="statistical-bottom-item-top-right flex-nowrap">
                  <span style="max-width: 100px" class="white-space">{{ item.author || item.created_user.nickname }}</span
                  >于
                  <span>{{ item.updated_at_humans }}</span>
                  编辑
                  <span style="font-size: 12px; color: #ffa509" v-if="item.rule_item"
                    ><span style="margin: 0 20px; color: #f0f1f5">|</span>+金币数：{{ item.rule_item && item.rule_item.details.val }}</span
                  >
                </div>
              </div>
              <div class="statistical-bottom-item-center space-between">
                <img :src="item.cover_picture" alt="" class="statistical-bottom-item-center-left" />
                <div class="statistical-bottom-item-center-right">
                  <div class="statistical-bottom-item-center-right-btm white-space-2" v-if="item.content_type !== 2" v-html="item.text">
                    <!--                    {{item.text}}-->
                  </div>
                  <div class="statistical-bottom-item-center-right-btm white-space-2" v-else>
                    {{ item.summaryText }}
                  </div>
                  <div class="source vertical-center">
                    <span v-if="item.create_type == 1">原创</span>
                    <span v-if="item.create_type == 2" class="source-zz white-space">转载来源:{{ item.source }}</span>
                  </div>
                  <el-button type="text" class="open-all vertical-center" @click="openall(item)">
                    阅读全文
                    <img src="~assets/images/index/btm.png" alt="" class="btm-img" />
                    <img src="~assets/images/index/btm-h.png" alt="" class="btm-img-h" />
                  </el-button>
                </div>
                <!--                <div class="statistical-bottom-item-content" v-else>-->
                <!--                  <img :src="item.cover_picture" alt="" class="" style="width: 100%">-->
                <!--                  <div class="content-text" v-html="item.content"  v-if="item.content_type !== 2"></div>-->
                <!--                  <div class="content-text" v-html="item.summary" v-else></div>-->
                <!--                  <el-button type="text"  class="open-all f-r" v-if="item.istext" @click="closeall()">-->
                <!--                    收起-->
                <!--                    <img src="~assets/images/index/up.png" alt="" class="btm-img">-->
                <!--                    <img src="~assets/images/index/up-h.png" alt="" class="btm-img-h">-->
                <!--                  </el-button>-->
                <!--                </div>-->
              </div>
              <div class="statistical-bottom-item-btm space-between">
                <div class="statistical-bottom-item-btm-left" v-if="activeName !== 'six' && activeName !== 'sev'">
                  <el-tag v-for="(tag, index) in item.tags.slice(0, 4)" :key="index" style="max-width: 110px" class="white-space">{{ tag.name }}</el-tag>
                </div>
                <div class="statistical-bottom-item-btm-right">
                  <el-button
                    v-if="is_admin_middleground === 'true'"
                    type="text"
                    class="timing"
                    style="margin-right: 10px"
                    @click="(istimeDialogVisible = true), (timeuuid = item.uuid), item.pre_released_at && (timevalue = item.pre_released_at), (istiming = true)"
                  >
                    <img src="~assets/images/index/timing.png" alt="" class="timing" />
                    <img src="~assets/images/index/timing-h.png" alt="" class="timing-h" />
                  </el-button>
                  <el-button type="text" class="edits" @click="edit(item)">
                    <img src="~assets/images/index/edit.png" alt="" class="edits-img" />
                    <img src="~assets/images/index/edit-h.png" alt="" class="edits-img-h" />
                  </el-button>
                  <el-button type="text" class="article" @click="toArticle(item)" v-if="item.content_type === 1" style="margin-left: 20px">
                    <img src="~assets/images/index/article-data.png" alt="" class="article-data" />
                    <img src="~assets/images/index/article-data-h.png" alt="" class="article-data-h" />
                  </el-button>
                  <el-button type="text" class="edit dels" @click="(isdelDialogVisible = true), (delid = item.uuid)" style="margin: 0 0 0 20px">
                    <img src="~assets/images/index/delete.png" alt="" class="dels-img" />
                    <img src="~assets/images/index/delete-hover.png" alt="" class="dels-img-h" />
                  </el-button>
                </div>
              </div>
              <el-divider></el-divider>
            </div>
          </div>
          <empty-prompt style="margin-top: 130px" v-else></empty-prompt>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="is_admin_middleground === 'true'" :label="num.advance > 9999 ? '定时发送 ' + (num.advance / 10000).toFixed(2) + 'W' : '定时发送 ' + num.advance" name="five">
        <div class="statistical-bottom-list" v-loading="loading">
          <div v-if="creationData.data && creationData.data.length">
            <div class="statistical-bottom-item" v-for="(item, index) in creationData.data" :key="index">
              <div class="statistical-bottom-item-top vertical-center">
                <el-tag v-if="item.content_type === 1">文章</el-tag>
                <el-tag v-else-if="item.content_type === 2">视频</el-tag>
                <el-tag v-else-if="item.content_type === 3">推广</el-tag>
                <div class="statistical-bottom-item-top-title white-space">{{ item.title }}</div>
                <div class="statistical-bottom-item-top-right flex-nowrap">
                  <span style="max-width: 100px" class="white-space">{{ item.author || item.created_user.nickname }}</span
                  >于
                  <span>{{ item.updated_at_humans }}</span>
                  编辑
                  <span style="font-size: 12px; color: #ffa509" v-if="item.rule_item"
                    ><span style="margin: 0 20px; color: #f0f1f5">|</span>+金币数：{{ item.rule_item && item.rule_item.details.val }}</span
                  >
                </div>
              </div>
              <div class="statistical-bottom-item-center space-between">
                <img :src="item.cover_picture" alt="" class="statistical-bottom-item-center-left" />
                <div class="statistical-bottom-item-center-right">
                  <div class="statistical-bottom-item-center-right-btm white-space-2" v-if="item.content_type !== 2" v-html="item.text">
                    <!--                    {{item.text}}-->
                  </div>
                  <div class="statistical-bottom-item-center-right-btm white-space-2" v-else>
                    {{ item.summaryText }}
                  </div>
                  <div class="source vertical-center">
                    <span v-if="item.create_type == 1">原创</span>
                    <span v-if="item.create_type == 2" class="source-zz white-space">转载来源:{{ item.source }}</span>
                  </div>
                  <el-button type="text" class="open-all vertical-center" @click="openall(item)">
                    阅读全文
                    <img src="~assets/images/index/btm.png" alt="" class="btm-img" />
                    <img src="~assets/images/index/btm-h.png" alt="" class="btm-img-h" />
                  </el-button>
                </div>
                <!--                <div class="statistical-bottom-item-content" v-else>-->
                <!--                  <img :src="item.cover_picture" alt="" class="" style="width: 100%">-->
                <!--                  <div class="content-text" v-html="item.content"  v-if="item.content_type !== 2"></div>-->
                <!--                  <div class="content-text" v-html="item.summary" v-else></div>-->
                <!--                  <el-button type="text"  class="open-all f-r" v-if="item.istext" @click="closeall()">-->
                <!--                    收起-->
                <!--                    <img src="~assets/images/index/up.png" alt="" class="btm-img">-->
                <!--                    <img src="~assets/images/index/up-h.png" alt="" class="btm-img-h">-->
                <!--                  </el-button>-->
                <!--                </div>-->
              </div>
              <div class="statistical-bottom-item-btm space-between">
                <div class="statistical-bottom-item-btm-left" v-if="activeName !== 'six' && activeName !== 'sev'">
                  <el-tag v-for="(tag, index) in item.tags.slice(0, 4)" :key="index" style="max-width: 110px" class="white-space">{{ tag.name }}</el-tag>
                </div>
                <div class="statistical-bottom-item-btm-right vertical-center">
                  <div class="" style="font-size: 12px; font-weight: 400; color: #93999f">发送时间 ： {{ item.pre_released_at }}</div>
                  <el-tag class="white-space qxtiming" @click="(isqxDialogVisible = true), (istiming = false), (timeuuid = item.uuid), (timevalue = item.pre_released_at)">取消定时</el-tag>
                  <el-button type="text" class="article" @click="toArticle(item)" v-if="item.content_type === 1" style="margin: 0 20px">
                    <img src="~assets/images/index/article-data.png" alt="" class="article-data" />
                    <img src="~assets/images/index/article-data-h.png" alt="" class="article-data-h" />
                  </el-button>
                </div>
              </div>
              <el-divider></el-divider>
            </div>
          </div>
          <empty-prompt style="margin-top: 130px" v-else></empty-prompt>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="is_admin_middleground === 'true'" :label="num.applied > 9999 ? '待审核 ' + (num.applied / 10000).toFixed(2) + 'W' : '待审核 ' + num.applied" name="six">
        <div class="statistical-bottom-list" v-loading="loading">
          <div v-if="creationData.data && creationData.data.length">
            <div v-for="(item, index) in creationData.data" :key="index">
              <div class="statistical-bottom-item">
                <div class="statistical-bottom-item-top vertical-center">
                  <el-tag v-if="item.content_type === 1">文章</el-tag>
                  <el-tag v-else-if="item.content_type === 2">视频</el-tag>
                  <el-tag v-else-if="item.content_type === 3">推广</el-tag>
                  <div class="statistical-bottom-item-top-title white-space">{{ item.title }}</div>
                  <div class="statistical-bottom-item-top-right flex-nowrap">
                    <span style="max-width: 100px" class="white-space">{{ item.author || item.created_user.nickname }}</span
                    >于
                    <span>{{ item.updated_at_humans }}</span>
                    编辑
                    <span style="font-size: 12px; color: #ffa509" v-if="item.rule_item"
                      ><span style="margin: 0 20px; color: #f0f1f5">|</span>+金币数：{{ item.rule_item && item.rule_item.details.val }}</span
                    >
                  </div>
                </div>
                <div class="statistical-bottom-item-center space-between">
                  <img :src="item.cover_picture" alt="" class="statistical-bottom-item-center-left" />
                  <img
                    src="~assets/images/data/play.png"
                    class="statistical-bottom-item-center-play"
                    alt=""
                    v-if="item.content_type === 2"
                    @click="videoPlay(item.title, item.media.content.m3u8.high, item.conversion)"
                  />
                  <div class="statistical-bottom-item-center-right">
                    <div class="statistical-bottom-item-center-right-btm white-space-2" v-if="item.content_type !== 2" v-html="item.text">
                      <!--                    {{item.text}}-->
                    </div>
                    <div class="statistical-bottom-item-center-right-btm white-space-2" v-else>
                      {{ item.summaryText }}
                    </div>
                    <div class="source vertical-center">
                      <span v-if="item.create_type == 1">原创</span>
                      <span v-if="item.create_type == 2" class="source-zz white-space">转载来源:{{ item.source }}</span>
                    </div>
                    <el-button type="text" class="open-all vertical-center" @click="openall(item)">
                      阅读全文
                      <img src="~assets/images/index/btm.png" alt="" class="btm-img" />
                      <img src="~assets/images/index/btm-h.png" alt="" class="btm-img-h" />
                    </el-button>
                  </div>
                </div>
                <div class="statistical-bottom-item-btm space-between">
                  <div class="statistical-bottom-item-btm-left"></div>
                  <div class="statistical-bottom-item-btm-right">
                    <el-button type="text" class="sh article" @click="examine(item)" style="margin-left: 20px"> 审核 </el-button>
                  </div>
                </div>
              </div>
              <el-divider></el-divider>
            </div>
          </div>
          <empty-prompt style="margin-top: 130px" v-else></empty-prompt>
        </div>
      </el-tab-pane>
      <el-tab-pane v-if="is_admin_middleground === 'true'" :label="num.reject > 9999 ? '已驳回 ' + (num.reject / 10000).toFixed(2) + 'W' : '已驳回 ' + num.reject" name="sev">
        <div class="statistical-bottom-list" v-loading="loading">
          <div v-if="creationData.data && creationData.data.length">
            <div v-for="(item, index) in creationData.data" :key="index">
              <div class="statistical-bottom-item">
                <div class="statistical-bottom-item-top vertical-center">
                  <el-tag v-if="item.content_type === 1">文章</el-tag>
                  <el-tag v-else-if="item.content_type === 2">视频</el-tag>
                  <el-tag v-else-if="item.content_type === 3">推广</el-tag>
                  <div class="statistical-bottom-item-top-title white-space">{{ item.title }}</div>
                  <div class="statistical-bottom-item-top-right flex-nowrap">
                    <span style="max-width: 100px" class="white-space">{{ item.author || item.created_user.nickname }}</span
                    >于
                    <span>{{ item.updated_at_humans }}</span>
                    编辑
                    <span style="font-size: 12px; color: #ffa509" v-if="item.rule_item"
                      ><span style="margin: 0 20px; color: #f0f1f5">|</span>+金币数：{{ item.rule_item && item.rule_item.details.val }}</span
                    >
                  </div>
                </div>
                <div class="statistical-bottom-item-center space-between">
                  <img :src="item.cover_picture" alt="" class="statistical-bottom-item-center-left" />
                  <img
                    src="~assets/images/data/play.png"
                    class="statistical-bottom-item-center-play"
                    alt=""
                    v-if="item.content_type === 2"
                    @click="videoPlay(item.title, item.media.content.m3u8.high, item.conversion)"
                  />
                  <div class="statistical-bottom-item-center-right">
                    <div class="statistical-bottom-item-center-right-btm white-space-2" v-if="item.content_type !== 2" v-html="item.text">
                      <!--                    {{item.text}}-->
                    </div>
                    <div class="statistical-bottom-item-center-right-btm white-space-2" v-else>
                      {{ item.summaryText }}
                    </div>
                    <div class="source vertical-center">
                      <span v-if="item.create_type == 1">原创</span>
                      <span v-if="item.create_type == 2" class="source-zz white-space">转载来源:{{ item.source }}</span>
                    </div>
                    <el-button type="text" class="open-all vertical-center" @click="openall(item)">
                      阅读全文
                      <img src="~assets/images/index/btm.png" alt="" class="btm-img" />
                      <img src="~assets/images/index/btm-h.png" alt="" class="btm-img-h" />
                    </el-button>
                  </div>
                </div>
                <div class="statistical-bottom-item-btm space-between vertical-center">
                  <div class="statistical-bottom-item-btm-left vertical-center"></div>
                  <div class="statistical-bottom-item-btm-right vertical-center">
                    <el-button type="text" class="sh bo article" @click="openOpinion('bh', item)" style="margin-left: 20px"> 驳回原因 </el-button>
                    <!-- <el-button type="text" class="edits" @click="edit(item)">
                      <img src="~assets/images/index/edit.png" alt="" class="edits-img" />
                      <img src="~assets/images/index/edit-h.png" alt="" class="edits-img-h" />
                    </el-button>
                    <el-button type="text" class="article" @click="toArticle(item)" v-if="item.content_type === 1" style="margin-left: 10px">
                      <img src="~assets/images/index/article-data.png" alt="" class="article-data" />
                      <img src="~assets/images/index/article-data-h.png" alt="" class="article-data-h" />
                    </el-button> -->
                  </div>
                </div>
              </div>
              <el-divider></el-divider>
            </div>
          </div>
          <empty-prompt style="margin-top: 130px" v-else></empty-prompt>
        </div>
      </el-tab-pane>
      <div class="statistical-bottom-list" v-if="isEltab !== 2" v-loading="loading">
        <div v-if="creationData.data && creationData.data.length">
          <div v-for="(item, index) in creationData.data" :key="index">
            <div class="statistical-bottom-item">
              <div class="statistical-bottom-item-top vertical-center">
                <el-tag v-if="item.content_type === 1">文章</el-tag>
                <el-tag v-else-if="item.content_type === 2">视频</el-tag>
                <el-tag v-else-if="item.content_type === 3">推广</el-tag>
                <el-tag class="dark-blue" v-if="item.publish_status === 0">草稿</el-tag>
                <el-tag class="dark-blue" v-else-if="item.publish_status === 1 && (is_admin_middleground === 'true' || item.examine_status === 1)">发布</el-tag>
                <el-tag class="dark-gray" v-else-if="item.publish_status === -1">关闭</el-tag>
                <el-tag class="dark-yellow" v-if="item.examine_status === 0 && is_admin_middleground === 'false' && item.is_author_source === 1">审核中</el-tag>
                <el-tag class="dark-red" v-if="item.examine_status === -1 && is_admin_middleground === 'false' && item.is_author_source === 1">未通过</el-tag>
                <div class="statistical-bottom-item-top-title white-space">{{ item.title }}</div>
                <div class="statistical-bottom-item-top-right flex-nowrap">
                  <span style="max-width: 100px" class="white-space">{{ item.author || item.created_user.nickname }}</span
                  >于
                  {{ item.updated_at_humans }}
                  编辑
                  <span style="font-size: 12px; color: #ffa509" v-if="item.rule_item"
                    ><span style="margin: 0 20px; color: #f0f1f5">|</span>+金币数：{{ item.rule_item && item.rule_item.details.val }}</span
                  >
                </div>
              </div>
              <div class="statistical-bottom-item-center space-between">
                <img :src="item.cover_picture" alt="" class="statistical-bottom-item-center-left" />
                <img
                  src="~assets/images/data/play.png"
                  class="statistical-bottom-item-center-play"
                  alt=""
                  v-if="item.content_type === 2"
                  @click="videoPlay(item.title, item.media.content.m3u8.high, item.conversion)"
                />
                <div class="statistical-bottom-item-center-right">
                  <div class="statistical-bottom-item-center-right-btm white-space-2" v-if="item.content_type !== 2">
                    {{ item.text }}
                  </div>
                  <div class="statistical-bottom-item-center-right-btm white-space-2" v-else>
                    {{ item.summaryText }}
                  </div>
                  <div class="source vertical-center">
                    <span v-if="item.create_type == 1">原创</span>
                    <span v-if="item.create_type == 2" class="source-zz white-space">转载来源:{{ item.source }}</span>
                  </div>
                  <el-button type="text" class="open-all" @click="openall(item)">
                    阅读全文
                    <img src="~assets/images/index/btm.png" alt="" class="btm-img" />
                    <img src="~assets/images/index/btm-h.png" alt="" class="btm-img-h" />
                  </el-button>
                </div>
                <!--                <div class="statistical-bottom-item-content" v-else>-->
                <!--                  <img :src="item.cover_picture" alt="" class="" style="width: 100%">-->
                <!--                  <div class="content-text" v-html="item.content" v-if="item.content_type !== 2"></div>-->
                <!--                  <div class="content-text" v-html="item.summary" v-else></div>-->
                <!--                  <el-button type="text"  class="open-all f-r" v-if="item.istext" @click="closeall()">-->
                <!--                    收起-->
                <!--                    <img src="~assets/images/index/up.png" alt="" class="btm-img">-->
                <!--                    <img src="~assets/images/index/up-h.png" alt="" class="btm-img-h">-->
                <!--                  </el-button>-->
                <!--                </div>-->
              </div>
              <div class="statistical-bottom-item-btm space-between vertical-center">
                <div class="statistical-bottom-item-btm-left vertical-center">
                  <el-button type="text" style="cursor: unset"
                    ><img src="~assets/images/index/peo.png" alt="" /> {{ item.read_count > 9999 ? (item.read_count / 10000).toFixed(2) + 'W' : item.read_count }}</el-button
                  >
                  <el-button type="text" @click="(goodPage = 1), good(item.uuid)">
                    <img src="~assets/images/index/good.png" alt="" />
                    {{ item.good_count > 9999 ? (item.good_count / 10000).toFixed(2) + 'W' : item.good_count }}
                  </el-button>
                  <el-button type="text" style="cursor: unset">
                    <img src="~assets/images/index/sc.png" alt="" />
                    {{ item.collect_count > 9999 ? (item.collect_count / 10000).toFixed(2) + 'W' : item.collect_count }}</el-button
                  >
                  <!-- <el-button type="text" @click="(commentPage = 1), comment(item.uuid, item.content_type, item.content_status)">
                    <img src="~assets/images/index/pl.png" alt="" />
                    {{ item.comment_count > 9999 ? (item.comment_count / 10000).toFixed(2) + 'W' : item.comment_count }}</el-button
                  > -->
                </div>
                <div class="statistical-bottom-item-btm-right vertical-center">
                  <div class="tag_item" v-if="activeName !== 'six' && activeName !== 'sev'">
                    <el-tag v-for="(tag, index) in item.tags.slice(0, 4)" :key="index" style="max-width: 110px" class="white-space">{{ tag.name }}</el-tag>
                  </div>

                  <el-tag effect="dark" class="opinion_btn" style="cursor: pointer" v-if="item.examine_status === -1 && is_admin_middleground === 'false'" @click="openOpinion('sh', item)"
                    >审核意见</el-tag
                  >
                  <el-tag effect="dark" :class="item.is_top === false ? 'dark-gray' : 'dark-orange'" style="cursor: pointer" @click="Topping(item.uuid)">置顶</el-tag>
                  <el-tag class="dark-blue" style="cursor: pointer" v-if="item.publish_status === 0 || item.publish_status === -1" @click="changeStatus(item.uuid)">显示</el-tag>
                  <el-tag class="dark-gray" style="cursor: pointer" v-if="item.publish_status === 1" @click="changeStatus(item.uuid)">隐藏</el-tag>
                  <el-button type="text" v-if="item.examine_status !== 0 || is_admin_middleground === 'true'" class="edit edits" @click="edit(item)" style="margin-right: 10px">
                    <img src="~assets/images/index/edit.png" alt="" class="edits-img" />
                    <img src="~assets/images/index/edit-h.png" alt="" class="edits-img-h" />
                  </el-button>
                  <el-dropdown @command="contentStatus($event, item.uuid, item.publish_status, item.title)" v-if="(item.status !== 1 && item.content_type === 2) || item.content_type === 1">
                    <span class="el-dropdown-link">
                      <img src="~assets/images/index/mores.png" class="moresimg" alt="" style="vertical-align: middle" />
                      <img src="~assets/images/index/mores-h.png" class="moresimgh" alt="" style="vertical-align: middle" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item command="5" v-if="item.content_type === 1">资料管理</el-dropdown-item>
                      <!-- <div v-if="item.content_type === 1" class="set-line"></div> -->
                      <!-- <el-dropdown-item command="1"
                        ><i class="el-icon-check" v-if="item.content_status === 1"></i><span v-else>&nbsp&nbsp&nbsp&nbsp&nbsp</span>允许任何人评论</el-dropdown-item
                      >
                      <el-dropdown-item command="2"
                        ><i class="el-icon-check" v-if="item.content_status === 2"></i><span v-else>&nbsp&nbsp&nbsp&nbsp&nbsp</span>评价筛选后显示</el-dropdown-item
                      >
                      <el-dropdown-item command="3"><i class="el-icon-check" v-if="item.content_status === 3"></i><span v-else>&nbsp&nbsp&nbsp&nbsp&nbsp</span>关闭评论</el-dropdown-item> -->

                      <div class="" v-if="item.publish_status !== 1">
                        <!-- <el-divider></el-divider> -->
                        <el-dropdown-item command="4">删除</el-dropdown-item>
                      </div>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
            </div>
            <el-divider></el-divider>
          </div>
        </div>
        <empty-prompt style="margin-top: 130px" v-else></empty-prompt>
      </div>

      <!--   分页   -->
      <el-pagination
        v-if="creationData.meta && creationData.meta.last_page > 1"
        class="page"
        background
        @current-change="handleCurrentChange"
        :current-page="page"
        :page-size="pageSize"
        layout="prev, pager, next,total, jumper"
        :total="creationData.meta && creationData.meta.total"
      >
      </el-pagination>
    </el-tabs>
    <!--    评论弹框-->
    <el-dialog class="dialog" title="评论" :visible.sync="isDialogVisible" top="20vh" width="658px" :destroy-on-close="true" :before-close="handleClose">
      <div class="total space-between">
        <div class="total-left">
          共有 <span class="total-left-text">{{ comments.meta ? comments.meta.total : 0 }}</span> 条数据
        </div>
        <el-button type="text" class="total-right" @click="commentSort(2)" v-if="commentsSort === 1">
          <img src="~assets/images/index/sort.png" alt="" />
          正序
        </el-button>
        <el-button type="text" class="total-right" @click="commentSort(1)" v-else>
          <img src="~assets/images/index/sort-h.png" alt="" />
          倒序
        </el-button>
      </div>
      <div style="max-height: 600px; overflow-y: auto; padding-right: 10px" v-if="comments.data && comments.data.length">
        <comment :comment="comments.data" :uuid="uuid" :type="commentType" :isreply="isReply" @commentsHandle="commentsChildren"></comment>

        <div class="align-center load" @click="commentMore()" v-if="comments.meta && comments.meta.last_page !== commentPage">
          <img src="~assets/images/index/more.png" alt="" />
          <span class="loadmore">加载更多</span>
        </div>
        <div class="align-center" v-else>
          <span class="loadmore">暂无更多</span>
        </div>
      </div>
      <empty-prompt style="margin-bottom: 130px" v-else></empty-prompt>

      <!--      </div>-->
    </el-dialog>
    <!--    点赞-->
    <el-dialog class="dzdialog" title="点赞人数" :visible.sync="isdzDialogVisible" v-if="isdzDialogVisible" top="20vh" width="360px" :destroy-on-close="true" :before-close="handleClose">
      <div class="total space-between">
        <div class="total-left">
          共有 <span class="total-left-text">{{ goodData.meta ? goodData.meta.total : 0 }}</span> 条数据
        </div>
      </div>

      <div class="three-list" v-loading="goodloading">
        <div class="goodlist" v-if="goodData.data && goodData.data.length">
          <div class="" v-for="(item, index) in goodData.data" :key="index" style="margin-right: 10px">
            <div class="three-list-item-header flex-nowrap vertical-center">
              <img :src="item.avatar" class="three-list-item-header-img" alt="" />
              <div class="three-list-item-header-right">
                <div class="list-item-content space-between">
                  <div class="list-item-content-left white-space">
                    {{ item.nickname }}
                  </div>
                </div>
              </div>
            </div>
            <el-divider></el-divider>
          </div>
          <div class="align-center load" @click="goodMore()" v-if="goodData.meta && goodData.meta.last_page !== goodPage">
            <img src="~assets/images/index/more.png" alt="" />
            <span class="loadmore">加载更多</span>
          </div>
          <div class="align-center" v-else>
            <span class="loadmore">暂无更多</span>
          </div>
        </div>
        <empty-prompt style="margin-bottom: 130px" v-else></empty-prompt>
      </div>
    </el-dialog>
    <!--    删除-->
    <el-dialog class="delDiolog" :visible.sync="isdelDialogVisible" top="calc( 50vh - 150px )" icon="el-icon-warning" :destroy-on-close="true" width="360px" center>
      <div class="dioTitle"><i class="el-icon-warning"></i> 这是您操作比较重要的数据</div>
      <span>你确定要删除该数据么？</span>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="isdelDialogVisible = false">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="del()">确 定</el-button>
      </span>
    </el-dialog>

    <!--    视频弹框-->
    <el-dialog class="dzdialog" :title="videoTitle" :visible.sync="isvideoDialogVisible" top="20vh" width="840px" v-if="isvideoDialogVisible" :before-close="handleClose">
      <div class="video-link vertical-center" v-if="conversion && conversion.url" @click="conversionLink(conversion.url)">
        <img src="~assets/images/data/link.png" alt="" class="link" />
        <img src="~assets/images/data/link-h.png" alt="" class="link-h" />
        <span style="margin-left: 10px">{{ conversion.name }}</span>
      </div>
      <play-video :file_id="media_id" class="play-video"></play-video>
    </el-dialog>
    <el-dialog class="alldialog" :title="content.title" v-if="isdialogVisibleContent" :visible.sync="isdialogVisibleContent" width="658px">
      <div class="dialog-content" v-html="content.content" style="font-size: 12px; font-weight: 400; color: #71777d; max-height: 600px; overflow-y: auto" v-if="content.content"></div>
      <div class="" v-else>
        <div class="video-link vertical-center" v-if="content.conversion && content.conversion.url" @click="conversionLink(content.conversion.url)">
          <img src="~assets/images/data/link.png" alt="" class="link" />
          <img src="~assets/images/data/link-h.png" alt="" class="link-h" />
          <span style="margin-left: 10px">{{ content.conversion.name }}</span>
        </div>
        <play-video :file_id="content.media && content.media.content && content.media.content.m3u8 && content.media.content.m3u8.high" class="play-video"></play-video>
        <div class="dialog-content" v-html="content.summary" style="font-size: 12px; font-weight: 400; color: #71777d; max-height: 600px; overflow-y: auto"></div>
      </div>
      <el-tag v-for="(tag, index) in content.tags" :key="index">{{ tag.name }}</el-tag>
      <div class="sources vertical-center">
        <span v-if="content.create_type == 1">原创</span>
        <span v-if="content.create_type == 2">转载来源:{{ content.source }}</span>
      </div>
      <!--      <span slot="footer" class="dialog-footer">-->
      <!--    <el-button type="primary" @click="isdialogVisibleContent = false" style="border-radius: 21px;width: 140px;height: 42px;">确 定</el-button>-->
      <!--  </span>-->
    </el-dialog>
    <el-dialog class="tmdialog" title="定时发送" :visible.sync="istimeDialogVisible" top="30vh" width="540px" v-if="istimeDialogVisible" :before-close="handleClose">
      <div class="flex-nowrap vertical-center">
        <div class="" style="word-break: keep-all; margin-right: 20px">发送时间</div>
        <el-date-picker
          v-model="timevalue"
          type="datetime"
          :clearable="false"
          placeholder="选择日期时间"
          style="width: 100%"
          format="yyyy-MM-dd HH:mm:ss"
          value-format="yyyy-MM-dd HH:mm:ss"
          :default-time="defaultTime"
          :picker-options="pickerOptions0"
        >
        </el-date-picker>
      </div>

      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="(istimeDialogVisible = false), (timeuuid = '')">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="timing()">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog class="delDiolog" :visible.sync="isqxDialogVisible" top="calc( 50vh - 150px )" icon="el-icon-warning" :destroy-on-close="true" width="360px" center>
      <div class="">
        <div class="dioTitle"><i class="el-icon-warning"></i> 这是您操作比较重要的数据</div>
        <div style="text-align: center">你确定取消于{{ timevalue }}发送该内容么？</div>
      </div>
      <span slot="footer" class="dialog-footer form-operation">
        <el-button class="form-operation-item" round @click="(isqxDialogVisible = false), (timeuuid = '')">取 消</el-button>
        <el-button class="form-operation-item" round type="primary" @click="timing()">确 定</el-button>
      </span>
    </el-dialog>
    <!--引导认证-->
    <SpringFrame
      :authentication="authentication"
      :bankCark="bankCark"
      @handleCloseAuthenticationBank="handleCloseAuthenticationBank"
      @handleCloseAuthentication="handleCloseAuthentication"
      @goBankPage="goBankPage"
    ></SpringFrame>
    <el-dialog class="opinion" title="审核意见" :visible.sync="opinionDialog" width="360px" :close-on-click-modal="false" @close="closeDialog">
      <div class="content">
        <div class="line"></div>
        <p class="tips">{{ reason }}</p>
      </div>
    </el-dialog>
    <!--审核弹框-->
    <el-dialog title="审核" :visible.sync="examineDialog" width="30%" class="dialog examine_dialog">
      <div class="examine">
        <el-form :model="examineForm" ref="ruleForm" label-width="100px" class="examine-ruleForm" :rules="rules">
          <el-form-item label="审核" prop="resource">
            <el-radio-group v-model="examineForm.approve_status">
              <el-radio label="success">同意</el-radio>
              <el-radio label="reject">拒绝</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item v-if="examineForm.approve_status === 'reject'" label="拒绝理由" class="is-required desc" prop="rejection_reason">
            <!-- <el-input type="textarea" maxlength="50" placeholder="请输入拒绝理由" v-model="examineForm.rejection_reason"></el-input> -->
            <el-input type="textarea" maxlength="50" placeholder="请输入拒绝理由" v-model="examineForm.rejection_reason"></el-input>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="closeExamine">取 消</el-button>
        <el-button class="confirm" type="primary" @click="examinSure('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import Comment from 'components/comment';
import PlayVideo from 'components/playVideo';
import SpringFrame from 'components/SpringFrame';
import _ from 'lodash';
import EmptyPrompt from 'components/EmptyPrompt';
import { loginConfigs } from '../../../http/api/auth';
export default {
  name: 'Index',
  components: {
    Comment,
    PlayVideo,
    EmptyPrompt,
    SpringFrame,
  },
  data() {
    return {
      rules: {
        rejection_reason: [{ required: true, message: '请输入拒绝理由', trigger: 'blur' }],
      },
      examineForm: {
        approve_status: 'success',
        rejection_reason: '',
      },
      reason: '',

      examineDialog: false,
      opinionDialog: false,
      authentication: false,
      bankCark: false,
      isEltab: 0,
      isAdd: true,
      //加载
      loading: false,
      //展开
      isOpen: 11,
      //评论
      isDialogVisible: false,
      //点赞
      isdzDialogVisible: false,
      //删除
      isdelDialogVisible: false,
      //视频
      isvideoDialogVisible: false,
      //全文
      isdialogVisibleContent: false,
      //定时发送
      istimeDialogVisible: false,
      isqxDialogVisible: false,
      istiming: false,
      videoTitle: '',
      //视频id
      media_id: '',
      delid: '',
      value1: '',
      conversion: {},
      activeName: 'first',
      value: '',
      input: '',
      content: [],
      multipleSelection: [],
      //上传图片
      imageUrl: '',
      formLabelAlign: {
        name: '',
        region: '',
        type: '',
      },
      basicForm: {},
      creationData: {}, // 创作接口数据
      // 筛选条件
      page: 1, // 当前页码
      pageSize: 10, // 当前数据
      optionsData: [
        {
          value: 'title',
          label: '标题',
        },
        {
          value: 'nickname',
          label: '昵称',
        },
        // {
        //   value: 'tag_name',
        //   label: '标签名'
        // }
      ], // 选项数据
      options: '', // 当前选项
      keywords: '', //关键词
      status: '', // 发布状态
      statusData: [
        // {
        //   value: '0',
        //   label: '草稿'
        // },
        {
          value: '1',
          label: '发布',
        },
        {
          value: '-1',
          label: '关闭',
        },
      ],
      type: '', // 内容类型
      typeData: [
        {
          value: '1',
          label: '文章',
        },
        {
          value: '2',
          label: '视频',
        },
      ],
      examineTitle: '',
      //评论列表
      comments: [],
      uuid: '', //uuid
      commentType: '', //评论类型
      commentPage: 1, //评论分页
      commentsSort: 1, //评论排序
      goodData: [], //点赞列表
      goodPage: 1, //点赞分页
      goodPageSize: 20,
      gooduuid: '', //点赞id
      tagvalue: '', //标签搜索+
      num: {
        all: '',
        article: '',
        video: '',
        draft: '',
        advance: '',
        applied: '',
        reject: '',
      },
      isReply: false,
      goodloading: false,
      timeuuid: '',
      timevalue: '',
      advance: 0,
      defaultTime: '',
      pickerOptions0: {
        selectableRange: new Date().getHours() + ':' + (new Date().getMinutes() + 5) + ':00 - 23:59:59',
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
        },
      },
      restaurants: [],
      approval: 2, // 职业认证
      bind_bank: 'false', // 绑定银行卡
      // is_circle_author: 'false', // 创作者
      is_admin_middleground: 'false', // 管理员
      is_has_benefit_circle: 'false', // 是否参与收益频道
    };
  },
  created() {
    this.approval = Number(localStorage.getItem('is_approval'));
    this.bind_bank = localStorage.getItem('is_bind_bank');
    this.is_circle_author = localStorage.getItem('is_circle_author');
    this.is_admin_middleground = localStorage.getItem('is_admin_middleground');
    this.is_has_benefit_circle = localStorage.getItem('is_has_benefit_circle');
    console.log(this.approval, '=========');
    console.log(this.bind_bank, 'oooo');
    if (this.approval !== 1 && this.is_admin_middleground === 'false') {
      this.authentication = true;
    } else if (this.approval === 1 && this.bind_bank === 'false' && this.is_admin_middleground === 'false' && this.is_has_benefit_circle === 'true') {
      this.bankCark = true;
    }
    //this.queryFun()
    // this.getNum()
    // if(this.$route.query.artivleType === 'four'){
    //   this.activeName = this.$route.query.artivleType
    //   this.status = 0
    //   this.type = ''
    //   this.isEltab = 2
    //   this.queryFun()
    //   this.getList()
    // }else {
    //   this.queryFun()
    //   this.getList()
    // }
    // this.authentication = true;
    // this.bankCark = true;
    if (this.$route.query.artivleType === 'first') {
      this.activeName = this.$route.query.artivleType;
      this.status = '';
      this.type = '';
      this.isEltab = 0;
      this.queryFun();
      this.getList();
    } else if (this.$route.query.artivleType === 'two') {
      this.activeName = this.$route.query.artivleType;
      this.status = '';
      this.type = 1;
      this.isEltab = 1;
      this.queryFun();
      this.getList();
    } else if (this.$route.query.artivleType === 'three') {
      this.activeName = this.$route.query.artivleType;
      this.status = '';
      this.type = 2;
      this.isEltab = 1;
      this.queryFun();
      this.getList();
    } else if (this.$route.query.artivleType === 'four') {
      this.activeName = this.$route.query.artivleType;
      this.status = 0;
      this.type = '';
      this.isEltab = 2;
      this.queryFun();
      this.getList();
    } else if (this.$route.query.artivleType === 'five') {
      this.activeName = this.$route.query.artivleType;
      this.status = 0;
      this.type = '';
      this.isEltab = 2;
      this.advance = 1;
      this.queryFun();
      this.getList();
    } else if (this.$route.query.artivleType === 'six') {
      this.activeName = this.$route.query.artivleType;
      this.status = 0;
      this.type = '';
      this.isEltab = 2;
      this.advance = 1;
      this.queryFun();
      this.getExamineStatus(0);
    } else if (this.$route.query.artivleType === 'sev') {
      this.activeName = this.$route.query.artivleType;
      this.status = 0;
      this.type = '';
      this.isEltab = 2;
      this.advance = 1;
      this.queryFun();
      this.getExamineStatus(-1);
    } else {
      this.queryFun();
      this.getList();
    }
  },
  computed: {
    // timevalues(){
    //   return this.timevalue
    // }
  },
  watch: {
    timevalue: function (newVal, oldVal) {
      let selectDate = newVal.slice(0, 10);
      let nowDate = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate()}`;
      if (selectDate == nowDate) {
        this.pickerOptions0.selectableRange = new Date().getHours() + ':' + (new Date().getMinutes() + 5) + ':00 - 23:59:59';
        this.defaultTime = new Date().getHours() + ':' + (new Date().getMinutes() + 5) + ':00';
        if (newVal.slice(11, 13) < new Date().getHours()) {
          this.timevalue = this.timevalue.substring(0, 10) + ' ' + this.defaultTime;
        }
      } else {
        this.pickerOptions0.selectableRange = '00:00:00 - 23:59:59';
        if (newVal.slice(0, 10) != oldVal.slice(0, 10)) {
          this.defaultTime = '00:00:00';
          this.timevalue = this.timevalue.substring(0, 10) + ' ' + this.defaultTime;
        }
      }
    },
  },
  mounted() {
    // this.$nextTick(
    //   function (){
    //     let dom = document.getElementsByClassName("statistical-bottom-item-center-right-btm")
    //     this.jsLine(2.5,dom);
    //   }
    // )
  },
  methods: {
    closeExamine() {
      this.examineDialog = false;
      this.examineForm.approve_status = 'success';
      this.examineForm.rejection_reason = '';
      this.uuid = '';
    },
    examinSure(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.examineForm);
          let data = {
            approve_status: this.examineForm.approve_status,
            rejection_reason: this.examineForm.rejection_reason,
          };
          let url = `${this.$api.contentsAdmin}/${this.uuid}/${this.$api.examine}`;
          this.$http.post(url, data, true).then((res) => {
            if (res.data.success) {
              this.$message({
                message: '操作成功',
                type: 'success',
              });
              this.closeExamine();
              this.getExamineStatus(0);
            } else {
              this.$message({
                message: '操作失败',
                type: 'error',
              });
            }
            console.log(res);
          });
        }
      });
    },
    examine(item) {
      this.examineDialog = true;
      this.uuid = item.uuid;
      console.log(item, '===========================');
    },
    openOpinion(type, item) {
      if (type === 'sh') {
        this.examineTitle = '审核意见';
      } else {
        this.examineTitle = '驳回原因';
      }
      this.reason = item.reject_reason;
      this.opinionDialog = true;
    },
    closeDialog() {
      this.opinionDialog = false;
    },
    // 取消弹框
    handleCloseAuthentication() {
      this.$router.go(-1);
    },
    // 取消弹框
    handleCloseAuthenticationBank() {
      this.bankCark = false;
    },
    // 绑定银行卡
    goBankPage() {
      this.$router.push('/profile?activeName=third');
    },
    querySearch(queryString, cb) {
      var restaurants = this.restaurants;
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createFilter(queryString) {
      return (restaurant) => {
        return restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0;
      };
    },
    //链接跳转
    conversionLink(url) {
      window.open(url);
    },
    //获取标签
    getTags() {
      let url = this.$api.tagsCircle;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          // this.options1 = res.data.data
          this.restaurants = res.data.data.map((terminal) => {
            return {
              value: terminal.name,
            };
          });
        }
      });
    },
    getNum() {
      let allurl = this.$api.categoryStatistics;
      this.$http.get(allurl, true).then((res) => {
        if (res.data.success) {
          this.num = res.data.data;
        }
      });
    },
    // 获取列表
    getList() {
      console.log(this.is_admin_middleground, 'ccccccccccccccccccccccccccccccccccccccccc');
      this.getNum();
      this.getTags();
      this.loading = true;
      let url =
        this.$api.DiscoverContents +
        `?page=${this.page}&pageSize=${this.pageSize}&options=${this.options}&keywords=${this.keywords}&status=${this.status}&type=${this.type}&advance=${this.advance}&tag_name=${this.tagvalue}`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.creationData = res.data.data;
          console.log(this.creationData, '--------------');
          for (let i = 0; i < this.creationData.data.length; i++) {
            this.creationData.data[i].text = this.creationData.data[i].content
              .replace(/<\/?.+?>/g, '')
              .replace(/&nbsp;/g, '')
              .replace(/&ldquo;/g, '')
              .replace(/&rdquo;/g, '');
            // if (this.creationData.data[i].text.length>110){
            //   this.creationData.data[i].text = this.creationData.data[i].text.substring(0,110) + '···';
            //   this.creationData.data[i].istext = true
            // }
            this.creationData.data[i].summaryText = this.creationData.data[i].summary;
            // if(this.creationData.data[i].summary.length>110){
            //   this.creationData.data[i].summaryText = this.creationData.data[i].summary.substring(0,110) + '···';
            //   this.creationData.data[i].istext = true
            // }
          }
          setTimeout(() => {
            this.loading = false;
          }, 800);
        }
      });
      setTimeout(() => {
        this.loading = false;
      }, 800);
    },
    // 待审核驳回列表
    getExamineStatus(type) {
      this.getNum();
      this.loading = true;
      let url = this.$api.contentsAdmin + `?page=${this.page}&pageSize=${this.pageSize}&options=${this.options}&keywords=${this.keywords}&examine_status=${type}`;
      console.log(url, '================');
      this.$http.get(url, true).then((res) => {
        console.log(res.data.data.data);
        console.log(res.data.success);
        if (res.data.success) {
          console.log('---');
          this.creationData = res.data.data;
          console.log(this.creationData, 'kkkkkkkkkkkkkkkkkkkkk');
          // for (let i = 0; i < this.creationData.data.length; i++) {
          //   this.creationData.data[i].text = this.creationData.data[i].content
          //     .replace(/<\/?.+?>/g, '')
          //     .replace(/&nbsp;/g, '')
          //     .replace(/&ldquo;/g, '')
          //     .replace(/&rdquo;/g, '');
          //   // if (this.creationData.data[i].text.length>110){
          //   //   this.creationData.data[i].text = this.creationData.data[i].text.substring(0,110) + '···';
          //   //   this.creationData.data[i].istext = true
          //   // }
          //   this.creationData.data[i].summaryText = this.creationData.data[i].summary;
          //   // if(this.creationData.data[i].summary.length>110){
          //   //   this.creationData.data[i].summaryText = this.creationData.data[i].summary.substring(0,110) + '···';
          //   //   this.creationData.data[i].istext = true
          //   // }
          // }
          setTimeout(() => {
            this.loading = false;
          }, 800);
        }
      });
    },
    // 筛选重置
    screenDefault() {
      this.options = '';
      this.keywords = '';
      this.tagvalue = '';
      if (this.isEltab !== 2) {
        this.status = '';
      }
      if (this.isEltab !== 1) {
        this.type = '';
      }
      this.screenSubmit();
    },
    // 判断是否审核列表
    isSh() {
      if (this.activeName === 'six') {
        this.getExamineStatus(0);
      } else if (this.activeName === 'sev') {
        this.getExamineStatus(-1);
      } else {
        this.getList();
      }
    },
    // 分页数据
    handleCurrentChange(val) {
      this.page = val;
      this.linkFun();
      this.isSh();
    },
    // 搜索查询
    screenSubmit() {
      console.log(this.activeName);
      this.page = 1;
      this.linkFun();
      this.isSh();
    },
    //点赞人数
    good(id) {
      this.goodloading = true;
      this.isdzDialogVisible = true;
      this.gooduuid = id;
      this.goodPage = 1;
      let url = this.$api.DiscoverContents + `/${id}/` + this.$api.goodUsers + `?pageSize=${this.goodPageSize}&&page=${this.goodPage}`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.goodData = res.data.data;
          setTimeout(() => {
            this.goodloading = false;
          }, 500);
        } else {
          setTimeout(() => {
            this.goodloading = false;
          }, 500);
        }
      });
      setTimeout(() => {
        this.goodloading = false;
      }, 500);
    },
    //点赞加载更多
    goodMore() {
      this.goodPage += 1;
      let url = this.$api.DiscoverContents + `/${this.gooduuid}/` + this.$api.goodUsers + `?pageSize=${this.goodPageSize}&&page=${this.goodPage}`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.goodData.data.push(...res.data.data.data);
          this.goodData.meta = res.data.data.meta;
        }
      });
    },
    //加载更多
    commentMore() {
      this.commentPage += 1;
      let url = this.$api.comments + `?type=${this.commentType}&uuid=${this.uuid}&page=${this.commentPage}&sort=${this.commentsSort}&pageSize=30`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.comments.data.push(...res.data.data.data);
          this.comments.meta = res.data.data.meta;
        }
      });
    },
    commentSort(val) {
      this.commentsSort = val;
      this.comment(this.uuid, this.commentType, this.isReply);
    },
    // 改变路由参数
    linkFun() {
      this.$router.push({
        path: '/creation/creation',
        query: {
          options: this.options,
          tagvalue: this.tagvalue,
          keywords: this.keywords,
          status: this.status,
          page: this.page,
          pageSize: this.pageSize,
          type: this.type,
          artivleType: this.activeName,
        },
      });
    },
    // 参数赋值
    queryFun() {
      let query = this.$route.query;
      if (query) {
        if (query.options) {
          this.options = query.options;
        }
        if (query.tagvalue) {
          this.tagvalue = query.tagvalue;
        }
        if (query.keywords) {
          this.keywords = query.keywords;
        }
        if (query.type) {
          this.type = query.type;
          if (query.type == 1) {
            this.activeName = 'two';
          } else if (query.type == 2) {
            this.activeName = 'three';
          }
        }
        if (query.status) {
          this.status = query.status;
          if (query.status == 0) {
            this.activeName = query.artivleType;
          }
        }
        if (query.page) {
          this.page = parseInt(query.page);
        }
        if (query.pageSize) {
          this.pageSize = parseInt(query.pageSize);
        }
        // if(query.activeName) {
        //   this.activeName= parseInt(query.activeName);
        // }
      }
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    //展开
    openall(val) {
      this.isdialogVisibleContent = true;
      this.content = val;
    },
    closeall() {
      this.isOpen = '';
    },
    //标签切换
    handleClick(tab) {
      this.creationData = {};
      this.page = 1;
      if (tab.name === 'first') {
        this.status = '';
        this.type = '';
        this.isEltab = 0;
        this.advance = 0;
        this.linkFun();
        this.getList();
      } else if (tab.name === 'two') {
        this.status = '';
        this.type = 1;
        this.isEltab = 1;
        this.advance = 0;
        this.linkFun();
        this.getList();
      } else if (tab.name === 'three') {
        this.status = '';
        this.type = 2;
        this.isEltab = 1;
        this.advance = 0;
        this.linkFun();
        this.getList();
      } else if (tab.name === 'four') {
        this.status = 0;
        this.type = '';
        this.isEltab = 2;
        this.advance = 0;
        this.linkFun();
        this.getList();
      } else if (tab.name === 'five') {
        this.status = 0;
        this.type = '';
        this.isEltab = 2;
        this.advance = 1;
        this.linkFun();
        this.getList();
      } else if (tab.name === 'six') {
        this.status = 0;
        this.type = '';
        this.isEltab = 2;
        this.advance = 0;
        this.linkFun();
        this.getExamineStatus(0);
      } else if (tab.name === 'sev') {
        this.status = 0;
        this.type = '';
        this.isEltab = 2;
        this.advance = 0;
        this.linkFun();
        this.getExamineStatus(-1);
      }
    },
    //置顶
    Topping: _.debounce(function (id) {
      let url = this.$api.DiscoverContents + `/` + id + this.$api.changeIsTop;
      this.$http
        .get(url, true)
        .then((res) => {
          if (res.data.success) {
            this.linkFun();
            this.getList();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    }, 500),
    //修改状态
    changeStatus: _.debounce(function (id) {
      let url = this.$api.DiscoverContents + `/` + id + this.$api.changeStatus;
      this.$http
        .get(url, true)
        .then((res) => {
          if (res.data.success) {
            this.linkFun();
            this.getList();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    }, 500),
    //编辑内容
    edit(val) {
      console.log(val);
      if (val.content_type === 1) {
        this.$router.push({
          path: '/article/text',
          query: {
            id: val.uuid,
          },
        });
      } else if (val.content_type === 2) {
        this.$router.push({
          path: '/article/video',
          query: {
            id: val.uuid,
          },
        });
      }
    },
    // 去资料管理
    toArticle(val) {
      this.$router.push({
        path: '/creation/materials',
        query: {
          id: val.uuid,
          title: val.title,
        },
      });
    },
    // 评价更新
    commentsChildren(data) {
      if (data === 'upload') {
        this.comment(this.uuid, this.commentType);
      }
    },
    //获取评论列表
    comment(id, type, status) {
      let ctype = '';
      if (type === 1 || type === 'article') {
        ctype = 'article';
      } else if (type === 2 || type === 'video') {
        ctype = 'video';
      }
      if (status === 3) {
        this.isReply = false;
      } else {
        this.isReply = true;
      }
      this.uuid = id;
      this.commentType = ctype;
      let url = this.$api.comments + `?type=${ctype}&uuid=${id}&page=${this.commentPage}&sort=${this.commentsSort}&pageSize=30`;
      this.$http.get(url, true).then((res) => {
        if (res.data.success) {
          this.comments = res.data.data;
          this.isDialogVisible = true;
        }
      });
    },
    //设置评论.
    contentStatus(command, id, type, title) {
      if (command === '5') {
        this.$router.push({
          path: '/creation/materials',
          query: {
            id: id,
            title: title,
          },
        });
      } else if (command === '4') {
        if (type === 1) {
          this.$message.warning('发布状态不能直接删除！');
        } else {
          this.isdelDialogVisible = true;
          this.delid = id;
        }
      } else {
        this.$confirm(`这是您操作比较重要的数据, 你确定要改变该数据？`, '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            let url = this.$api.DiscoverContents + `/` + id + this.$api.contentStatus;
            let data = {
              content_status: command,
            };
            this.$http
              .post(url, data, true)
              .then((res) => {
                if (res.data.success) {
                  this.$message.success('修改成功');
                  this.linkFun();
                  this.getList();
                } else {
                  this.$message.error(res.data.message);
                }
              })
              .catch((error) => {
                this.$message.error(error.response.data.message);
              });
          })
          .catch(() => {});
      }
    },
    //视频弹框
    videoPlay(title, id, conversion) {
      this.videoTitle = title;
      this.media_id = id;
      this.conversion = conversion;
      this.isvideoDialogVisible = true;
    },
    //跳转页面
    go(command) {
      if (command === 'text') {
        this.$router.push('/article/text');
      } else if (command === 'video') {
        this.$router.push('/article/video');
      }
    },
    //文本隐藏
    jsLine(line, className) {
      let textLength = className[0]['childNodes'][0].length; //文本长度
      let fontSize = getComputedStyle(className[0], undefined).getPropertyValue('font-size').substr(0, 2); //获取字体大小
      let lineNum = Math.round(className[0].clientWidth / fontSize); //四舍五入,一行有多少字
      if (textLength > lineNum * line) {
        let newText = className[0].innerText.substr(0, Math.round(lineNum * line)) + '...';
        className[0].innerText = newText;
      }
    },
    //定时发送
    timing() {
      // console.log(this.timevalue)
      if (this.istiming) {
        let url = this.$api.DiscoverContents + '/' + `${this.timeuuid}` + this.$api.changeAdvance;
        let data = {
          pre_released_at: this.timevalue,
        };
        this.$http.post(url, data, true).then((res) => {
          if (res.data.success) {
            this.$message.success('设置成功');
            this.istimeDialogVisible = false;
            this.timevalue = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate() + 1}` + ' ' + '00:00:00 - 23:59:59';
            this.timeuuid = '';
            this.linkFun();
            this.getList();
          }
        });
      } else {
        let url = this.$api.DiscoverContents + '/' + `${this.timeuuid}` + this.$api.changeAdvance;
        let data = {
          pre_released_at: null,
        };
        this.$http.post(url, data, true).then((res) => {
          if (res.data.success) {
            this.$message.success('取消成功');
            this.isqxDialogVisible = false;
            this.timevalue = `${new Date().getFullYear()}-${new Date().getMonth() + 1}-${new Date().getDate() + 1}` + ' ' + '00:00:00 - 23:59:59';
            this.timeuuid = '';
            this.linkFun();
            this.getList();
          }
        });
      }
    },

    //弹窗关闭
    handleClose(done) {
      done();
    },

    //下拉点击
    handleCommand(command) {
      command[0] === 'a' && ((this.activeName = 'two'), (this.isEltab = 2));
    },
    // 修改标签状态
    putTags(command, item) {
      // this.current_item= item
    },
    //删除标签
    del() {
      let url = this.$api.DiscoverContents + `/` + this.delid;
      this.$http
        .del(url, true)
        .then((res) => {
          if (res.data.success) {
            this.isdelDialogVisible = false;
            this.$message.success('删除成功');
            this.linkFun();
            this.getList();
            this.getNum();
          } else {
            this.$message.error(res.data.message);
          }
        })
        .catch((error) => {
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>

<style lang="less" scoped>
@import '~assets/less/article/index.less';
.set-line {
  width: 142px;
  height: 1px;
  background: #ebeff2;
  margin: 10px;
}
</style>
