<template>
  <div class="">
    <!--引导绑定认证-->
    <el-dialog class="authentication fiset_dialog" title="提示" :visible.sync="authenticationDialog" width="360px" :close-on-click-modal="false" @close="handleCloseAuthentication">
      <div class="content">
        <div class="line"></div>
        <div class="top_title">
          <img src="~assets/images/data/warning.png" alt="" />
          <p>您还未完成职业认证</p>
        </div>
        <p class="tips">请先在手机端完成职业认证！</p>
      </div>
    </el-dialog>
    <!--引导绑定银行卡-->
    <el-dialog class="authentication" :visible.sync="bankDialog" width="360px" :show-close="false" :close-on-click-modal="false">
      <div class="content">
        <div class="top_title bank_title">
          <img src="~assets/images/data/warning.png" alt="" />
          <p>您还绑定银行卡</p>
        </div>
        <p class="tips">绑定银行卡，用于创作收益收款</p>
        <span slot="footer" class="dialog-footer">
          <el-button @click="handleCloseAuthenticationBank">稍后再说</el-button>
          <el-button class="confirm" type="primary" @click="goBankPage">立即绑卡</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>
<script>
export default {
  name: 'SpringFrame',
  props: {
    authentication: {
      type: Boolean,
      default: false,
    },
    bankCark: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {};
  },
  computed: {
    authenticationDialog: {
      get() {
        return this.authentication;
      },
      set(v) {},
    },
    bankDialog: {
      get() {
        return this.bankCark;
      },
      set(v) {},
    },
  },
  created() {},
  mounted() {},
  methods: {
    handleCloseAuthentication() {
      this.$emit('handleCloseAuthentication');
    },
    handleCloseAuthenticationBank() {
      this.$emit('handleCloseAuthenticationBank');
    },
    goBankPage() {
      this.$emit('goBankPage');
    },
  },

  components: {},
};
</script>
<style lang="scss" scoped>
:deep(.el-dialog) {
  height: 200px !important;
  margin-top: 30vh !important;
  .el-dialog__header {
    padding: 0 !important;
  }
  .el-dialog__header:after {
    display: none !important;
  }
  .el-dialog__body {
    padding: 0;
    margin-top: 16px;
  }
}
.authentication {
  //   height: 200px;
  :deep(.el-dialog__header) {
    padding: 0;
    .el-dialog__title {
      margin-top: 20px;
      margin-left: 20px;
      display: inline-block;
    }
  }
  .content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .line {
      width: 100%;
      height: 1px;
      background: #ebeff2;
    }
    .top_title {
      margin-top: 44px;
      display: flex;
      align-items: center;
      justify-content: center;
      img {
        margin-right: 10px;
      }
      p {
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: bold;
        color: #1c1f21;
      }
    }
    .bank_title {
      margin: 0;
    }
    .tips {
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #71777d;
      margin-top: 19px;
    }
    .dialog-footer {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 29px;
      .el-button {
        width: 120px;
        height: 42px;
        border: 1px solid #b7bbbf;
        border-radius: 21px;
        font-size: 14px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #93999f;
      }
      .el-button:hover {
        color: #71777d;
        border-color: #93999f;
        background-color: rgba(147, 153, 159, 0.1);
      }
      .confirm {
        background: #81d8d0;
        border-color: #65c5bc;
        border: 0;
        color: #ffffff;
        margin-left: 26px;
      }
      .confirm:hover {
        color: #ffffff;
        background: #65c5bc;
      }
    }
  }
}
</style>
